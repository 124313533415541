import styled, { css } from 'styled-components';

import { TooltipProps } from '@components/tooltip/types';
import { device } from '@styles/media';

const Help = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 26px;

  ${({ align }: TooltipProps) =>
    align === 'left' &&
    css`
      left: 0;
    `}

  ${({ align }: TooltipProps) =>
    align === 'right' &&
    css`
      right: 0;
    `}

  ${({ align }: TooltipProps) =>
    align === 'right-long' &&
    css`
      right: 35px;
    `}

  ${({ align }: TooltipProps) =>
    align === 'right-short' &&
    css`
      right: 28px;
    `}
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  top: 0;
  z-index: 1;
`;

const Message = styled.p`
  width: 100%;
  width: 300px;
  display: inline-block;
  background: rgba(21, 21, 22, 0.9) url('/images/ico-help.svg') no-repeat 20px center;
  cursor: pointer;
  position: absolute;
  top: 29px;
  border-radius: 15px;
  color: ${({ theme }) => theme.tokens.gray[100]};
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  padding: 20px 20px 20px 56px;
  z-index: 100;
  display: none;
  margin: 0;

  ${({ display }: TooltipProps) =>
    display &&
    css`
      display: block;
    `}

  ${({ align }: TooltipProps) =>
    align === 'left' &&
    css`
      left: 0;
    `}

  ${({ align }: TooltipProps) =>
    align === 'right' &&
    css`
      right: 0;
    `}

  ${({ align }: TooltipProps) =>
    align === 'right-long' &&
    css`
      right: -35px;
    `}

  ${({ align }: TooltipProps) =>
    align === 'right-short' &&
    css`
      right: -28px;
    `}

    @media ${device.mobile} {
    width: 275px;
  }
`;

export default {
  Help,
  Icon,
  Message,
};
